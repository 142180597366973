import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  ButtonStyle,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  FontStyle,
} from "app/pages/widgets/CRMStyles";

const OutlookConfiguration = ({ open8, setOpen8 }) => {
  const token = localStorage.getItem("accesstoken");
  const userId = localStorage.getItem("UserId");

  const header = {
    headers: {
      "db":"crmuseradmin",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [id, setId] = useState("");
  const [mailId, setMailId] = useState("");
  const [mailId1, setMailId1] = useState("");
  const [mailPassword, setMailPassword] = useState("");
  

  const [smtpHost, setSmtpHost] = useState("");
  const [smtpPort, setSmtpPort] = useState("");
  const [imapHost, setImapHost] = useState("");
  const [imapPort, setImapPort] = useState("");

  // update mail configuration
  const handleSubmitMailConfigure = (e) => {
    e.preventDefault();
    if (!id) {
      var payload = {
        SMTPHost: smtpHost,
        SMTPPort: smtpPort,
        IMAPHost: imapHost,
        IMAPPort: imapPort,
        MailId: mailId,
        MailPassword: mailPassword,
        User_Id: parseInt(localStorage.getItem("UserId")),
        Organization_Id: parseInt(localStorage.getItem("OrganizationId")),
        Created_By: parseInt(localStorage.getItem("UserId")),
        Updated_By: parseInt(localStorage.getItem("UserId")),
      };
      axios
        .post(`${BASE_URL}/user/UserOutlookConfigurationCRUD/`, payload, header)
        .then((res) => {
          console.log("res", res);

          if (res?.status === 200 && res.data?.id) {
            toast.success("Mail Configured Successfully");
            setOpen8(false);
          } else if (mailId1 === mailId) {
            toast.warning("Your Mail is Already Configured");
          } else if (mailId1 != mailId) {
            toast.warning("Please Configure Your Mail Id");
          } else {
            toast.error("Check Your Mail and Password");
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            const errorMessage = error.response.data.details || "You don't have permission to do it";
            console.log(errorMessage);
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 100,
            });
          } else {
            toast.error(`${error}`, {
              position: "top-right",
              autoClose: 100,
            });
          }
        });
    } else {
      var payload = {
        id: id,
        SMTPHost: smtpHost,
        SMTPPort: smtpPort,
        IMAPHost: imapHost,
        IMAPPort: imapPort,
        MailId: mailId,
        MailPassword: mailPassword,
        Organization_Id: parseInt(localStorage.getItem("OrganizationId")),
        User_Id: parseInt(localStorage.getItem("UserId")),
        Created_By: parseInt(localStorage.getItem("UserId")),
        Updated_By: parseInt(localStorage.getItem("UserId")),
      };
      axios
        .put(`${BASE_URL}/user/UserOutlookConfigurationCRUD/`, payload, header)
        .then((res) => {
          console.log("res", res);
          if (res?.status === 200 && res.data?.id) {
            toast.success("Mail Configured Updated Successfully");
            setOpen8(false);
          } else if (mailId1 === mailId) {
            toast.warning("Your Mail is Already Configured");
          } else if (mailId1 != mailId) {
            toast.warning("Please Configure Your Mail Id");
          } else {
            toast.error("Check Your Mail and Password");
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            const errorMessage = error.response.data.details || "You don't have permission to do it";
            console.log(errorMessage);
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 100,
            });
          } else {
            toast.error(`${error}`, {
              position: "top-right",
              autoClose: 100,
            });
          }
        });
    }
  };

  useEffect(async () => {
    if (userId) {
      await axios
        .get(
          `${BASE_URL}/user/UserOutlookConfigurationCRUD/?User_Id=${userId}`,
          header
        )
        .then((res) => {
          console.log(res);
          if (res) {
            setId(res.data?.id);
            setMailId(res.data?.MailId);
            setMailId1(res.data?.MailId);
            setMailPassword(res.data?.MailPassword);
            setSmtpHost(res.data?.SMTPHost);
            setSmtpPort(res.data?.SMTPPort);
            setImapHost(res.data?.IMAPHost);
            setImapPort(res.data?.IMAPPort);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <>
      <Dialog open={open8} onClose={() => setOpen8(false)}>
        <form onSubmit={handleSubmitMailConfigure}>
          <Div sx={{ p: 3, width: "500px" }}>
            <Typography variant="h3">Mail Configuration</Typography>
            <DialogContent>
              <Grid container>
                <Grid item>
                  <Div className="row">
                    <Typography className="input-label col-12">
                      Mail Id <span className="required">*</span>
                    </Typography>
                    <TextField
                      className="input-box2 col-12 ps-2"
                      name="mailId"
                      value={mailId}
                      onChange={(e) => setMailId(e.target.value)}
                      placeholder="Enter Your Mail Id"
                      sx={{ minWidth: "350px" }}
                    />
                  </Div>
                </Grid>
                <Grid item>
                  <Div className="row">
                    <Typography className="input-label col-12">
                      Mail Password <span className="required">*</span>
                    </Typography>
                    <TextField
                      className="input-box2 col-12 ps-2"
                      type={showPassword ? "text" : "password"}
                      name="mailPassword"
                      value={mailPassword}
                      onChange={(e) => setMailPassword(e.target.value)}
                      placeholder="Enter Your Mail Password"
                      autoComplete="off"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{ minWidth: "300px" }}
                    />
                  </Div>
                </Grid>
                <Grid item>
                  <Div className="row">
                    <Typography className="input-label col-12">
                      SMTP Host <span className="required">*</span>
                    </Typography>
                    <TextField
                      className="input-box2 col-12 ps-2"
                      name="smtpHost"
                      value={smtpHost}
                      onChange={(e) => setSmtpHost(e.target.value)}
                      placeholder="Enter SMTP Host"
                      sx={{ minWidth: "320px" }}
                    />
                  </Div>
                </Grid>
                <Grid item>
                  <Div className="row">
                    <Typography className="input-label col-12">
                      SMTP Port <span className="required">*</span>
                    </Typography>
                    <TextField
                      className="input-box2 col-12 ps-2"
                      name="smtpPort"
                      value={smtpPort}
                      onChange={(e) => setSmtpPort(e.target.value)}
                      placeholder="Enter SMTP Port"
                      sx={{ minWidth: "320px" }}
                    />
                  </Div>
                </Grid>
                <Grid item>
                  <Div className="row">
                    <Typography className="input-label col-12">
                      IMAP Host <span className="required">*</span>
                    </Typography>
                    <TextField
                      className="input-box2 col-12 ps-2"
                      name="imapHost"
                      value={imapHost}
                      onChange={(e) => setImapHost(e.target.value)}
                      placeholder="Enter IMAP Host"
                      sx={{ minWidth: "320px" }}
                    />
                  </Div>
                </Grid>
                <Grid item>
                  <Div className="row">
                    <Typography className="input-label col-12">
                      IMAP Port <span className="required">*</span>
                    </Typography>
                    <TextField
                      className="input-box2 col-12 ps-2"
                      name="imapPort"
                      value={imapPort}
                      onChange={(e) => setImapPort(e.target.value)}
                      placeholder="Enter IMAP Port"
                      sx={{ minWidth: "320px" }}
                    />
                  </Div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
              {id ? (
                <Button
                  type="submit"
                  sx={[DialogBoxSaveButton, FontStyle, ButtonStyle]}
                >
                  Update
                </Button>
              ) : (
                <Button
                  type="submit"
                  sx={[DialogBoxSaveButton, FontStyle, ButtonStyle]}
                >
                  Submit
                </Button>
              )}

              <Button sx={[DialogBoxCancelButton, FontStyle, ButtonStyle]}
              onClick={()=>{
                setOpen8(false);
                
              }}>
                Cancel
              </Button>
            </DialogActions>
          </Div>
        </form>
      </Dialog>
    </>
  );
};

export default OutlookConfiguration;
