import { Autocomplete, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import Div from "@jumbo/shared/Div";
import { BASE_URL } from "app/services/auth-services";
import { Cancelbutton, CustomButton, } from "app/pages/widgets/StylesComponents";


const BotConfiguration = ({ open10, setOpen10 }) => {
    const token = localStorage.getItem("accesstoken");
    const organizationId = localStorage.getItem("OrganizationId");
    const userId = localStorage.getItem("UserId");

    const header = {
        headers: {
            "db": "crmuseradmin",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    };

    const [id, setId] = useState("");
    const [accountId, setAccountId] = useState("");
    const [authToken, setAuthToken] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [mobileNo1, setMobileNo1] = useState("");
    const [introAgentId, setIntroAgentId] = useState("");
    const [introAgentName, setIntroAgentName] = useState("");
    const [qualifyAgentId, setQualifyAgentId] = useState("");
    const [qualifyAgentName, setQualifyAgentName] = useState("");

    const [agentLists, setAgentLists] = useState([]);

    const cancelFunc = () => {
        setOpen10(false);
    }

    // update mail configuration
    const handleSubmitBotConfigure = (e) => {
        e.preventDefault();
        if (!id) {
            var payload = {
                AgentID: introAgentId,
                AgentName: qualifyAgentName,
                User_Id: parseInt(localStorage.getItem("UserId")),
                Organization_Id: parseInt(localStorage.getItem("OrganizationId")),
                Created_Date: new Date().toISOString().split("T")[0],
                Created_By: parseInt(localStorage.getItem("UserId")),
                Updated_By: parseInt(localStorage.getItem("UserId")),
            };
            axios
                .post(`${BASE_URL}/user/AICallBotConfigurationCRUD/`, payload, header)
                .then((res) => {
                    if (res?.data?.id) {
                        toast.success("Bot Configured Successfully");
                        setOpen10(false);
                    } else if (mobileNo === mobileNo1) {
                        toast.warning("Bot is Already Configured");
                    } else if (mobileNo != mobileNo1) {
                        toast.warning("Please Configure Bot");
                    } else {
                        toast.error("Check Your Mobile Number");
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 403) {
                        const errorMessage = error.response.data.details || "You don't have permission to do it";
                        console.log(errorMessage);
                        toast.error(errorMessage, {
                            position: "top-right",
                            autoClose: 100,
                        });
                    } else {
                        toast.error(`${error}`, {
                            position: "top-right",
                            autoClose: 100,
                        });
                    }
                });
        } else {
            var payload = {
                id: id,
                AgentID: introAgentId,
                AgentName: qualifyAgentName,
                Organization_Id: parseInt(localStorage.getItem("OrganizationId")),
                User_Id: parseInt(localStorage.getItem("UserId")),
                Created_By: parseInt(localStorage.getItem("UserId")),
                Updated_By: parseInt(localStorage.getItem("UserId")),
            };
            axios
                .put(`${BASE_URL}/user/AICallBotConfigurationCRUD/`, payload, header)
                .then((res) => {
                    if (res?.data?.id) {
                        toast.success("Bot Configured Updated Successfully");
                        setOpen10(false);
                    } else if (mobileNo === mobileNo1) {
                        toast.warning("Bot is Already Configured");
                    } else if (mobileNo != mobileNo1) {
                        toast.warning("Please Configure Bot");
                    } else {
                        toast.error("Check Your Mobile Number");
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 403) {
                        const errorMessage = error.response.data.details || "You don't have permission to do it";
                        console.log(errorMessage);
                        toast.error(errorMessage, {
                            position: "top-right",
                            autoClose: 100,
                        });
                    } else {
                        toast.error(`${error}`, {
                            position: "top-right",
                            autoClose: 100,
                        });
                    }
                });
        }
    };

    useEffect(async () => {
        if (userId) {
            await axios
                .get(
                    `${BASE_URL}/user/AICallbotconfigurationgetapi/?id=${userId}`,
                    header
                )
                .then((res) => {
                    if (res?.data) {
                        setId(res?.data?.id);
                        setIntroAgentId(res?.data?.AgentID);
                        setQualifyAgentName(res?.data?.AgentName);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, []);

    const getAgentLists = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/user/DialogFlowAgentList/`, header)
            if (response?.data) {
                setAgentLists(response?.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getAgentLists();
    }, []);


    return (
        <>
            <Dialog minWidth={"xs"} open={open10} onClose={() => setOpen10(false)}>
                <form onSubmit={handleSubmitBotConfigure}>
                    <Div sx={{ p: 3 }}>
                        <Typography variant="h3">Call Bot Configuration</Typography>
                        <DialogContent>
                            <Grid container sx={{ p: 0, m: 0 }}>
                                <Grid item>
                                    <Div className="row">
                                        <Typography className="input-label col-12">
                                            Introduction Call Bot <span className="required">*</span>
                                        </Typography>
                                        {/* <TextField
                                            className="input-box2 col-12 ps-2"
                                            name="accountId"
                                            value={accountId}
                                            onChange={(e) => setAccountId(e.target.value)}
                                            placeholder="Enter Your Bot Account Id"
                                            sx={{ minWidth: { xs: "100px", md: "350px" }, maxWidth: "500px" }}
                                        /> */}
                                        <Autocomplete
                                            getOptionLabel={(option) => {
                                                if (typeof option === "string") {
                                                    return option;
                                                }
                                                if (option && option?.Display_Name) {
                                                    return option?.Display_Name;
                                                }
                                                return "";
                                            }}
                                            options={agentLists}
                                            value={introAgentName}
                                            onChange={(e, newValue) => {
                                                setIntroAgentId(newValue?.id);
                                                setIntroAgentName(newValue?.Display_Name);
                                            }}
                                            onFocus={getAgentLists}
                                            id="outlined-basic"
                                            className="search-select3 w-full"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Select Agent"
                                                    sx={{ borderRadius: "10px", minWidth: { xs: "100px", md: "350px" }, maxWidth: "500px" }}
                                                />
                                            )}
                                        />
                                    </Div>
                                </Grid>
                                <Grid item>
                                    <Div className="row">
                                        <Typography className="input-label col-12">
                                            Qualification Call Bot <span className="required">*</span>
                                        </Typography>
                                        <Autocomplete
                                            getOptionLabel={(option) => {
                                                if (typeof option === "string") {
                                                    return option;
                                                }
                                                if (option && option?.Display_Name) {
                                                    return option?.Display_Name;
                                                }
                                                return "";
                                            }}
                                            options={agentLists}
                                            value={qualifyAgentName}
                                            onChange={(e, newValue) => {
                                                setQualifyAgentId(newValue?.id);
                                                setQualifyAgentName(newValue?.Display_Name);
                                            }}
                                            id="outlined-basic"
                                            className="search-select3 w-full"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Select Agent"
                                                    sx={{ borderRadius: "10px", minWidth: { xs: "100px", md: "350px" }, maxWidth: "500px" }}
                                                />
                                            )}
                                        />
                                    </Div>
                                </Grid>
                                {/* <Grid item>
                                    <Div className="row">
                                        <Typography className="input-label col-12">
                                            Agent Name <span className="required">*</span>
                                        </Typography>
                                        <Autocomplete
                                            getOptionLabel={(option) => {
                                                if (typeof option === "string") {
                                                    return option;
                                                }
                                                if (option && option) {
                                                    return option;
                                                }
                                                return "";
                                            }}
                                            options={agentLists}
                                            value={agentName}
                                            onChange={(e, newValue) => {
                                                setAgentName(newValue);
                                            }}
                                            id="outlined-basic"
                                            className="search-select3 w-full"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        borderRadius: "10px",
                                                    }}
                                                    placeholder="Select Agent"
                                                />
                                            )}
                                        />
                                    </Div>
                                </Grid> */}
                            </Grid>
                        </DialogContent>
                        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                            {id ? (
                                <CustomButton label="Update" type="submit" Icon="saveIcon" />
                            ) : (
                                <CustomButton label="Save" type="submit" Icon="saveIcon" />
                            )}
                            <Cancelbutton label="Cancel" onClick={() => cancelFunc()} />

                        </DialogActions>
                    </Div>
                </form>
            </Dialog>
        </>
    );
}


export default BotConfiguration
