import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import {
  Grid,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Grow,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import RepeatOutlinedIcon from "@mui/icons-material/RepeatOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import axios from "axios";
import { BASE_URL, Tenantname } from "app/services/auth-services";
import MailConfiguration from "./MailConfiguration";
import { CiCalendar, CiMail } from "react-icons/ci";
import CalendarConfiguration from "./CalendarConfiguration";
import GMailConfiguration from "./GmailConfiguration";
import MapConfiguration from "./MapConfiguration";
import LinkedinConfiguration from "./LinkedinConfiguration";
import FacebookConfiguration from "./FacebookConfiguration";
import InstagramConfiguration from "./InstagramConfiguration";
import {
  GmailSecertKey,
  getGeoLocationKey,
} from "app/redux/reducers/locationApp";
import KnowlarityConfiguration from "./KnowlarityConfiguration";
import { BsChatText } from "react-icons/bs";
import OutlookConfiguration from "./OutlookConfigurations";
import { FaCamera, FaUserCircle } from "react-icons/fa";
import { MdCall, MdEmail } from "react-icons/md";
import { AvatarCircle, Cancelbutton, CRMSeparateDateTime, CustomButton } from "app/pages/widgets/StylesComponents";
import { DisplayFlex } from "app/pages/widgets/CRMStyles";
import { toast } from "react-toastify";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import TwilioConfiguration from "./TwilioConfiguration";
import BotConfiguration from "./BotConfiguration";


const AuthUserDropdown = () => {
  const navigate = useNavigate();
  const { theme } = useJumboTheme();
  const set = localStorage.getItem("Username");
  const token = localStorage.getItem("accesstoken");

  let userDetails = localStorage.getItem("UserDetails");
  userDetails = JSON.parse(userDetails);
  const [frommail, setFromMail] = useState(
    `${userDetails && userDetails?.email}`
  );

  const header = {
    headers: {
      "db": "crmuseradmin1",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const [openUserProfile, setOpenUserProfile] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open9, setOpen9] = useState(false);
  const [open10, setOpen10] = useState(false);

  const [editDetails, setEditDetails] = useState({
    UserName: false,
    UserEmail: false,
    UserMobileNo: false,
    UserCountry: false,
    UserState: false,
    UserStreet: false,
    UserCity: false,
    UserArea: false,
    UserBuildingNo: false,
    EditStaus: false,
  });
  const [editDetails1, setEditDetails1] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileName, setFileName] = useState("");
  const [base64, setBase64] = useState("");

  // update login status
  const updateLoginStatus = async () => {
    try {
      const token = localStorage.getItem("accesstoken");
      const username = localStorage.getItem("Username");
      await axios.patch(
        `${BASE_URL}/useradmin/UserCRUD/`,
        {
          User_Login_Status: false,
          username: username,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            db: "crmuseradmin1",
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const updatePassWordStatus = () => {
    if (
      userDetails?.Password_Status === false &&
      userDetails?.Navigate_Status === false
    ) {
      axios
        .patch(
          `${BASE_URL}/useradmin/UserCRUD/`,
          {
            id: localStorage.getItem("UserId"),
            username: userDetails && userDetails?.username,
            Password_Status: true,
          },
          {
            headers: {
              db: "crm",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log("get appointments list", res.data);
          window.location.href = `https://admin.crmfarm.in/InstanceEditList/?id=${Tenantname}`;
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      // navigate(`/${Tenantname}`);
      navigate(`/`);
      localStorage.removeItem("selectedItems");
      localStorage.removeItem("selectedItems1");
      localStorage.removeItem("accesstoken");
      localStorage.removeItem("UserId");
      localStorage.removeItem("OrganizationId");
      localStorage.removeItem("UserType");
    }
  };

  //   no action auto logout
  const inactivityTimeout = 12000000; // 5 minutes in milliseconds
  let logoutTimer;

  const resetTimeout = () => {
    clearTimeout(logoutTimer);
    logoutTimer = setTimeout(onLogout, inactivityTimeout);
  };

  // update user details
  const handleSubmitUserDetails = async (e) => {
    try {
      let payload = {
        "username": editDetails?.username,
        "first_name": editDetails?.first_name,
        "last_name": editDetails?.last_name,
        "email": editDetails?.email,
        "Designation": editDetails?.Designation,
        "User_Photo": base64 || editDetails?.User_Photo,
        "User_Photo_Name": fileName || "file.jpg",
        "PhoneNo": editDetails?.PhoneNo,
        "BuildingHuseNo": editDetails?.BuildingHuseNo,
        "Street": editDetails?.Street,
        "Area": editDetails?.Area,
        "City": editDetails?.City,
        "State": editDetails?.State,
        "Country": editDetails?.Country,
      };
      console.log("payload", payload);

      await axios.patch(`${BASE_URL}/useradmin/UserCRUD/`, payload, header)
        .then((res) => {
          toast.success("User Details Updated Successfully");
          localStorage.setItem("UserDetails", JSON.stringify(res?.data));
          let clear = {
            UserName: false,
            UserEmail: false,
            UserMobileNo: false,
            UserCountry: false,
            UserState: false,
            UserStreet: false,
            UserCity: false,
            UserArea: false,
            UserBuildingNo: false,
            EditStaus: false,
          }
          console.log("clear", clear);
          setEditDetails(clear)
          setFromMail(res?.data?.email)
          setOpenUserProfile(false)
        }).catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleChangeEdit = () => {
    setEditDetails(prev => ({
      ...prev,
      UserName: true,
      UserEmail: true,
      UserMobileNo: true,
      UserCountry: true,
      UserState: true,
      UserStreet: true,
      UserCity: true,
      UserArea: true,
      UserBuildingNo: true,
      EditStaus: true,
    }))
  }

  const HandleCacel = () => {
    setEditDetails({
      ...editDetails1,
      UserName: false,
      UserEmail: false,
      UserMobileNo: false,
      UserCountry: false,
      UserState: false,
      UserStreet: false,
      UserCity: false,
      UserArea: false,
      UserBuildingNo: false,
      EditStaus: false,
    })
    getFetchImage(editDetails1) // reftech the user image
  }

  const onLogout = () => {
    // setAuthToken(null);
    updateLoginStatus();
    updatePassWordStatus();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("File uploaded:", file);
      setEditDetails({ ...editDetails, User_Photo: file?.name || "no name" });
      setFileName(file?.name);
      const imageUrl = URL.createObjectURL(file);
      console.log("imageUrl:", imageUrl);
      setSelectedImage(imageUrl);

      const reader = new FileReader();
      reader.onload = handleReaderLoaded;
      reader.readAsBinaryString(file);
    }
  };

  const handleReaderLoaded = (e) => {
    const binaryString = e.target.result;
    setBase64(btoa(binaryString));
  };

  const getFetchImage = (data) => {
    try {
      if (data?.User_Photo) {
        let url = data?.User_Photo;
        let parts = url.split('/media/');

        let image = (BASE_URL + data?.User_Photo?.split("https://backend.crmfarm.app")[1] || data?.User_Photo) ?? "";

        setSelectedImage(image);
        setFileName(parts[1]);
        console.log("image upload", parts[1], { imageSample: data?.User_Photo, imgae2: data?.User_Photo?.split("https://backend.crmfarm.app")[1] });
        if (Boolean(data?.User_Photo)) {
          try {
            fetch(`${BASE_URL}${data?.User_Photo}`)
              .then((data) => data.arrayBuffer())
              .then((buffer) => {
                const base64 = btoa(
                  new Uint8Array(buffer).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ""
                  )
                );
                setBase64(base64);
                const urlParts = data?.User_Photo.split("/");
                console.log("urlParts", urlParts);
              });
          } catch (error) {
            console.log("error", error);
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const getUserDetails = async () => {
    try {
      let response = await axios.get(`${BASE_URL}/useradmin/UserCRUD/?username=${userDetails?.username}`, header)
      console.log("responsesssss", response);
      let data = response?.data
      setEditDetails({
        ...editDetails,
        "username": data?.username,
        "first_name": data?.first_name,
        "last_name": data?.last_name,
        "email": data?.email,
        "Designation": data?.Designation,
        "User_Photo": base64 || data?.User_Photo,
        "User_Photo_Name": fileName || "file.jpg",
        "PhoneNo": data?.PhoneNo,
        "BuildingHuseNo": data?.BuildingHuseNo,
        "Street": data?.Street,
        "Area": data?.Area,
        "City": data?.City,
        "State": data?.State,
        "Country": data?.Country,
      });
      setEditDetails1({
        ...editDetails,
        "username": data?.username,
        "first_name": data?.first_name,
        "last_name": data?.last_name,
        "email": data?.email,
        "Designation": data?.Designation,
        "User_Photo": base64 || data?.User_Photo,
        "User_Photo_Name": fileName || "file.jpg",
        "PhoneNo": data?.PhoneNo,
        "BuildingHuseNo": data?.BuildingHuseNo,
        "Street": data?.Street,
        "Area": data?.Area,
        "City": data?.City,
        "State": data?.State,
        "Country": data?.Country,
      });
      getFetchImage(data)
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    // if (Boolean(editDetails?.EditStaus)) return;
    getUserDetails()
  }, [openUserProfile]);

  useEffect(() => {
    resetTimeout();
    const events = ["mousemove", "keydown"];
    const handleUserActivity = () => {
      resetTimeout();
    };
    events.forEach((event) => {
      window.addEventListener(event, handleUserActivity);
    });
    return () => {
      clearTimeout(logoutTimer);
      events.forEach((event) => {
        window.removeEventListener(event, handleUserActivity);
      });
    };
  }, [navigate]);

  useEffect(() => {
    getGeoLocationKey();
    GmailSecertKey();
  }, []);

  // let [userDetails?.last_login?.split('T')[1] = "", userDetails?.last_login?.split('T')[1] = ""] = userDetails?.last_login && userDetails?.last_login?.split('T');

  console.log("userdetailssssss", { userDetails });
  console.log("editDetailsssss", editDetails);
  return (
    <Div>
      <ThemeProvider theme={theme}>
        <JumboDdPopover
          triggerButton={
            <JumboIconButton elevation={25}>
              <Div sx={{ display: "flex" }}>
                <Avatar
                  sizes={"small"}
                  sx={{
                    boxShadow: 25,
                    cursor: "pointer",
                    backgroundColor: "#ffffff",
                    // fontSize: "16px",
                    // color: "black",
                    // backgroundColor: "#dff3dc",
                    fontSize: "16px",
                    color: "#0c5c00",
                    padding: 1.25,
                    '&:hover': {
                      backgroundColor: "#F3F3F3",
                      boxShadow: "1px 1px 1px solid black",
                    }
                  }}
                >
                  {set && set[0].toUpperCase()}
                  {set && set[1].toUpperCase()}
                </Avatar>
              </Div>
            </JumboIconButton>

          }
        >
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              p: (theme) => theme.spacing(2.5),
            }}
          >
            <Avatar sx={{ width: 60, height: 60, mb: 2 }}>
              {set && set[0].toUpperCase()}
              {set && set[1].toUpperCase()}
            </Avatar>
            <Typography variant={"h5"}>{set}</Typography>
            <Typography variant={"body1"} color="text.secondary">
              {frommail}
            </Typography>
          </Div>
          <Divider />
          <nav>
            <List disablePadding sx={{ pb: 1 }}>
              <ListItemButton onClick={() => setOpenUserProfile(true)}>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <PersonOutlineIcon style={{ fontSize: "22px" }} />
                </ListItemIcon>
                <ListItemText primary="Profile" sx={{ my: 0 }} />
              </ListItemButton>
              {/* <ListItemButton>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <EditOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Edit Profile" sx={{ my: 0 }} />
              </ListItemButton> */}
              {localStorage.getItem("UserType") !== "User" && (
                <ListItemButton onClick={() => navigate(`/ChatandSupport`)}>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <BsChatText size={17} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Chat & Support"
                    sx={{ my: 0 }}
                  />
                </ListItemButton>
              )}
              <ListItemButton
                onClick={() => {
                  setOpen(true);
                }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CiMail size={17} />
                </ListItemIcon>
                <ListItemText primary="Mail Configuration" sx={{ my: 0 }} />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setOpen1(true);
                }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CiCalendar size={17} />
                </ListItemIcon>
                <ListItemText primary="Calendar Configuration" sx={{ my: 0 }} />
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  setOpen2(true);
                }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CiCalendar size={17} />
                </ListItemIcon>
                <ListItemText primary="Gmail Configuration" sx={{ my: 0 }} />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setOpen8(true);
                }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CiCalendar size={17} />
                </ListItemIcon>
                <ListItemText primary="Outlook Configuration" sx={{ my: 0 }} />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setOpen3(true);
                }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CiCalendar size={18} />
                </ListItemIcon>
                <ListItemText primary="Map Configuration" sx={{ my: 0 }} />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setOpen4(true);
                }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CiCalendar size={17} />
                </ListItemIcon>
                <ListItemText primary="Linkedin Configuration" sx={{ my: 0 }} />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setOpen5(true);
                }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CiCalendar size={17} />
                </ListItemIcon>
                <ListItemText primary="Facebook Configuration" sx={{ my: 0 }} />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setOpen6(true);
                }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CiCalendar size={17} />
                </ListItemIcon>
                <ListItemText
                  primary="Instagram Configuration"
                  sx={{ my: 0 }}
                />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setOpen7(true);
                }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CiCalendar size={17} />
                </ListItemIcon>
                <ListItemText
                  primary="Knowlarity Configuration"
                  sx={{ my: 0 }}
                />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setOpen9(true);
                }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CiCalendar size={17} />
                </ListItemIcon>
                <ListItemText
                  primary="Twilio Configuration"
                  sx={{ my: 0 }}
                />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setOpen10(true);
                }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CiCalendar size={17} />
                </ListItemIcon>
                <ListItemText
                  primary="Bot Configuration"
                  sx={{ my: 0 }}
                />
              </ListItemButton>
              <ListItemButton onClick={() => navigate("/samples/content-layout")}>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <RepeatOutlinedIcon style={{ fontSize: "20px" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Switch User"
                  sx={{ my: 0 }}
                />
              </ListItemButton>
              <ListItemButton onClick={onLogout}>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <LogoutIcon style={{ fontSize: "20px" }} />
                </ListItemIcon>
                <ListItemText primary="Logout" sx={{ my: 0 }} />
              </ListItemButton>
            </List>
          </nav>
        </JumboDdPopover>
      </ThemeProvider>

      {/* dialog box for user profile */}
      <Dialog
        open={openUserProfile}
        onClose={() => setOpenUserProfile(false)}
        aria-labelledby="draggable-dialog-title"
        maxWidth="md"
        fullWidth
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Div sx={{ p: 3, minHeight: "400px" }}>
          <Typography variant="h3">User Profile</Typography>
          <DialogContent sx={{ minHeight: "300px", padding: { xs: "4px !important" } }}>
            <Grow
              in={true}
              style={{ transformOrigin: '0 0 0' }}
              timeout={1000}
            >
              <Grid container sx={{ my: 2, backgroundColor: "#ededed !important", borderRadius: "10px" }}>
                <Grid item xs={12} md={5.5} sx={[DisplayFlex, { justifyContent: "center", my: 2.5 }]}>
                  <Div sx={{ position: "relative" }}>
                    <AvatarCircle
                      image={selectedImage}
                      bgColor={"#115857"}
                      boxShadow="box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;"
                      size="120px"
                      fontsize="32px"
                    />
                    {Boolean(editDetails?.EditStaus) &&
                      <Div sx={{ position: "absolute", bottom: "0px", right: "5px", zIndex: 9999, cursor: 'pointer !important' }}>
                        <IconButton
                          size="small"
                          style={{
                            backgroundColor: '#e9e9e9',
                            cursor: 'pointer !important'
                          }}
                        >
                          <input
                            type="file"
                            placeholder="Profile Upload"
                            onChange={handleImageChange}
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              zIndex: 1,
                              width: '100%',
                              height: '100%',
                              cursor: 'pointer !important'
                            }}
                          />
                          <FaCamera color="#000" />
                        </IconButton>
                      </Div>
                    }
                  </Div>
                </Grid>

                <Grid item xs={12} md={0.5} sx={{ mt: 2 }}>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Div sx={{ mt: 0.8, display: "flex", alignItems: "center" }}>
                    <IconButton className="col-2" sx={{ backgroundColor: "transparent !important" }}>
                      <FaUserCircle color="black" />
                    </IconButton>
                    {editDetails?.UserName === false ?
                      <Typography className="input-label col-7">
                        {editDetails?.first_name} -  {editDetails?.last_name}
                      </Typography>
                      :
                      <Div className="col-9" sx={{ display: "flex", gap: "10px" }}>
                        <TextField
                          className="input-box col-8"
                          name="first_name"
                          value={editDetails?.first_name}
                          onChange={(e) => setEditDetails({ ...editDetails, first_name: e.target.value })}
                          placeholder="Enter Your First Name"
                        />
                        <TextField
                          className="input-box col-4"
                          name="last_name"
                          value={editDetails?.last_name}
                          onChange={(e) => setEditDetails({ ...editDetails, last_name: e.target.value })}
                          placeholder="Enter Your Last Name"
                          sx={{ pr: { xs: 0, md: 1 } }}
                        />
                      </Div>
                    }
                  </Div>
                  <Div sx={{ mt: 0.8, display: "flex", alignItems: "center" }}>
                    <IconButton className="col-2" sx={{ backgroundColor: "transparent !important" }}>
                      <MdEmail color="black" />
                    </IconButton>
                    {editDetails?.UserEmail === false ?
                      <Typography className="input-label col-7">
                        {editDetails?.email}
                      </Typography>
                      :
                      <TextField
                        className="input-box col-9"
                        name="email"
                        value={editDetails?.email}
                        onChange={(e) => setEditDetails({ ...editDetails, email: e.target.value })}
                        placeholder="Enter Your Email Id"
                      />
                    }
                  </Div>
                  <Div sx={{ mt: 0.8, display: "flex", alignItems: "center" }}>
                    <IconButton className="col-2" sx={{ backgroundColor: "transparent !important" }}>
                      <MdCall color="black" />
                    </IconButton>
                    {editDetails?.UserMobileNo === false ?
                      <Typography className="input-label col-7">
                        {editDetails?.PhoneNo || ""}
                      </Typography>
                      :
                      <TextField
                        className="input-box col-9"
                        name="PhoneNo"
                        value={editDetails?.PhoneNo}
                        onChange={(e) => setEditDetails({ ...editDetails, PhoneNo: e.target.value })}
                        placeholder="Enter Your Mobile No"
                      />
                    }
                  </Div>
                </Grid>
              </Grid>
            </Grow>

            <Grow
              in={true}
              style={{ transformOrigin: '0 0 0' }}
              timeout={1000}
            >
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} md={1} sx={{ mt: 2 }}>
                </Grid>

                <Grid item xs={12} md={5.5} sx={{ mt: 2 }}>
                  <Div sx={{ mt: 0.8, display: "flex", alignItems: "center" }}>
                    <Typography className="input-label col-sm-11 col-md-4">Role</Typography>
                    <Typography className="input-label col-1">:</Typography>
                    <Typography className="input-label col-sm-12 col-md-6">
                      {editDetails?.Designation}
                    </Typography>
                  </Div>
                  <Div sx={{ mt: 0.8, display: "flex", alignItems: "center" }}>
                    <Typography className="input-label col-sm-11 col-md-4">Country</Typography>
                    <Typography className="input-label col-1">:</Typography>
                    {editDetails?.UserCountry === false ?
                      <Typography className="input-label col-sm-12 col-md-6">
                        {editDetails?.Country}
                      </Typography>
                      :
                      <TextField
                        className="input-box col-6"
                        name="Country"
                        value={editDetails?.Country}
                        onChange={(e) => setEditDetails({ ...editDetails, Country: e.target.value })}
                        placeholder="Enter Your Country"
                      />
                    }
                  </Div>
                  <Div sx={{ mt: 0.8, display: "flex", alignItems: "center" }}>
                    <Typography className="input-label col-sm-11 col-md-4">
                      City
                    </Typography>
                    <Typography className="input-label col-1">:</Typography>
                    {editDetails?.UserName === false ?
                      <Typography className="input-label col-sm-12 col-md-6">
                        {editDetails?.City}
                      </Typography>
                      :
                      <TextField
                        className="input-box col-6"
                        name="City"
                        value={editDetails?.City}
                        onChange={(e) => setEditDetails({ ...editDetails, City: e.target.value })}
                        placeholder="Enter Your City"
                      />
                    }
                  </Div>

                  <Div sx={{ mt: 0.8, display: "flex", alignItems: "center" }}>
                    <Typography className="input-label col-sm-11 col-md-4">Area</Typography>
                    <Typography className="input-label col-1">:</Typography>
                    {editDetails?.UserCity === false ?
                      <Typography className="input-label col-sm-12 col-md-6">
                        {editDetails?.Area}
                      </Typography>
                      :
                      <TextField
                        className="input-box col-6"
                        name="Area"
                        value={editDetails?.Area}
                        onChange={(e) => setEditDetails({ ...editDetails, Area: e.target.value })}
                        placeholder="Enter Your Area Name"
                      />
                    }
                  </Div>
                  <Div sx={{ mt: 0.8, display: "flex", alignItems: "center" }}>
                    <Typography className="input-label col-sm-11 col-md-4">
                      Last Login
                    </Typography>
                    <Typography className="input-label col-1">:</Typography>
                    <Typography className="input-label col-sm-12 col-md-6">
                      {/* {userDetails?.last_login?.split('T')[1] && userDetails?.last_login?.split('T')[1]} - {userDetails?.last_login?.split('T')[1] && userDetails?.last_login?.split('T')[1]?.split(".")[0]} {" "} {editDetails?.last_login && parseInt(editDetails?.last_login?.slice(11, 16), 10) >= 12 ? "PM" : "AM"} */}
                      {CRMSeparateDateTime(editDetails?.last_login || "") || " - "} {" "} {editDetails?.last_login && parseInt(editDetails?.last_login?.slice(11, 16), 10) >= 12 ? "PM" : editDetails?.last_login && "AM"}
                    </Typography>
                  </Div>
                </Grid>

                <Grid item xs={12} md={5.5} sx={{ mt: 2 }}>
                  <Div sx={{ mt: 0.8, display: "flex", alignItems: "center" }}>
                    <Typography className="input-label col-sm-11 col-md-4">
                      Password
                    </Typography>
                    <Typography className="input-label col-1">:</Typography>
                    <Typography className="input-label col-sm-12 col-md-6">
                      {'*'?.repeat(11)}
                    </Typography>
                  </Div>
                  <Div sx={{ mt: 0.8, display: "flex", alignItems: "center" }}>
                    <Typography className="input-label col-sm-11 col-md-4">
                      State
                    </Typography>
                    <Typography className="input-label col-1">:</Typography>
                    {editDetails?.UserName === false ?
                      <Typography className="input-label col-sm-12 col-md-6">
                        {editDetails?.State}
                      </Typography>
                      :
                      <TextField
                        className="input-box col-6"
                        name="State"
                        value={editDetails?.State}
                        onChange={(e) => setEditDetails({ ...editDetails, State: e.target.value })}
                        placeholder="Enter Your State"
                      />
                    }
                  </Div>
                  <Div sx={{ mt: 0.8, display: "flex", alignItems: "center" }}>
                    <Typography className="input-label col-sm-11 col-md-4">
                      Street
                    </Typography>
                    <Typography className="input-label col-1">:</Typography>
                    {editDetails?.UserState === false ?
                      <Typography className="input-label col-sm-12 col-md-6">
                        {editDetails?.Street}
                      </Typography>
                      :
                      <TextField
                        className="input-box col-6"
                        name="Street"
                        value={editDetails?.Street}
                        onChange={(e) => setEditDetails({ ...editDetails, Street: e.target.value })}
                        placeholder="Enter Your Street Name"
                      />
                    }
                  </Div>
                  <Div sx={{ mt: 0.8, display: "flex", alignItems: "center" }}>
                    <Typography className="input-label col-sm-11 col-md-4">
                      BuildingHuseNo
                    </Typography>
                    <Typography className="input-label col-1">:</Typography>
                    {editDetails?.UserName === false ?
                      <Typography className="input-label col-sm-12 col-md-6">
                        {editDetails?.BuildingHuseNo}
                      </Typography>
                      :
                      <TextField
                        className="input-box col-6"
                        name="BuildingHuseNo"
                        value={editDetails?.BuildingHuseNo}
                        onChange={(e) => setEditDetails({ ...editDetails, BuildingHuseNo: e.target.value })}
                        placeholder="Enter Your Building House No"
                      />
                    }
                  </Div>
                </Grid>
              </Grid>
            </Grow>
          </DialogContent>
          <DialogActions className="buttons" sx={{ display: "flex", justifyContent: "end" }}>
            <Grow
              in={true}
              style={{ transformOrigin: '0 0 0' }}
              {...(true ? { timeout: 1000 } : {})}
            >
              <Grid container mt={2} align="right" spacing={2} pb={2}>
                <Grid item align="right" xs={6}>
                  {!Boolean(editDetails?.EditStaus) ?
                    <CustomButton label="Edit" Icon="EditIcon" onClick={() => handleChangeEdit()} />
                    :
                    <CustomButton label="Update" Icon="saveIcon" onClick={() => handleSubmitUserDetails()} />
                  }
                </Grid>
                <Grid item align="left" xs={6}>
                  {!Boolean(editDetails?.EditStaus) ?
                    <Cancelbutton label={"Cancel"} onClick={() => setOpenUserProfile(false)} />
                    :
                    <Cancelbutton label={"Back"} Icon="backIcon" onClick={() => HandleCacel()} />
                  }
                </Grid>
              </Grid>
            </Grow>
          </DialogActions>
        </Div>
      </Dialog>

      <Div>
        <MailConfiguration open={open} setOpen={setOpen} />
      </Div>
      <Div>
        <CalendarConfiguration open1={open1} setOpen1={setOpen1} />
      </Div>
      <Div>
        <GMailConfiguration open2={open2} setOpen2={setOpen2} />
      </Div>
      <Div>
        <MapConfiguration open3={open3} setOpen3={setOpen3} />
      </Div>
      <Div>
        <LinkedinConfiguration open4={open4} setOpen4={setOpen4} />
      </Div>
      <Div>
        <FacebookConfiguration open5={open5} setOpen5={setOpen5} />
      </Div>
      <Div>
        <InstagramConfiguration open6={open6} setOpen6={setOpen6} />
      </Div>
      <Div>
        <KnowlarityConfiguration open7={open7} setOpen7={setOpen7} />
      </Div>
      <Div>
        <OutlookConfiguration open8={open8} setOpen8={setOpen8} />
      </Div>
      <Div>
        <TwilioConfiguration open9={open9} setOpen9={setOpen9} />
      </Div>
      <Div>
        <BotConfiguration open10={open10} setOpen10={setOpen10} />
      </Div>
    </Div>
  );
};

export default AuthUserDropdown;